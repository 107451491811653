<template>
  <template v-if="!idNullOrEmpty">
    <SetLoader v-if="!iframeShow" />
    <iframe
      :src="this.iframeSrc"
      class="hidden w-100 h-100 mt-2"
      id="dashboardReportView"
      frameborder="0"
    ></iframe>
  </template>
</template>
<script>
import $ from "jquery";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: "Dashboard",
  data() {
    return {
      idNullOrEmpty: true,
      iframeShow: false,
      iframeSrc: null,
    };
  },
  components: {},
  mounted() {
    this.getDashboardView();
  },
  methods: {
    getDashboardView() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);

      this.idNullOrEmpty = String.isNullOrWhiteSpace(this.$route.params.id);
      this.iframeSrc = null;
      this.iframeShow = false;
      if (this.idNullOrEmpty) return;

      var self = this,
        model = {
          url:
            !this.idNullOrEmpty && this.$route.params.id == "ViewerList"
              ? "/DashboardReporting/ViewerList"
              : "/DashboardReporting/Viewer/" + this.$route.params.id,
        };
      this.$appAxios
        .post("/CreateKeyForCurrentUser", model)
        .then((response) => {
          var token = response.data;
          if (!String.isNullOrWhiteSpace(token)) {
            this.iframeSrc = `${this.$root.getReportingWebSiteUrl()}/${
              this.$setXRMReportAuthorizeActionUrl
            }?key=${encodeURIComponent(token)}`;

            var iFrame = $("#dashboardReportView");
            iFrame.on("load", function () {
              setCurrentPageBreadcrumbs(
                self.$t(
                  "DashboardView",
                  {},
                  { locale: self.$store.state.activeLang }
                ),
                []
              );
              self.$root.changeDocumentTitle(
                self.$t(
                  "DashboardView",
                  {},
                  { locale: self.$store.state.activeLang }
                )
              );

              self.iframeShow = true;
              iFrame.removeClass("hidden");
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  watch: {
    "$route.params.id"() {
      if (this.$route.name === "Dashboard") {
        this.getDashboardView();
      }
    },
  },
};
</script>
